.XmxxjsRenwushu {
  width: 100%;
  display: grid;
  padding-top: 84px;

  .jianshe_3 {

  }

  .hit-red {
    color: #FF3000;
    margin-right: 4px;
  }

  .XmxxjsRenwushuCont {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    width: 100%;
    //height: 1500px;
    background: #ffffff;
    margin-top: 20px;

    .title-top {
      width: calc(100% - 100px);
      text-align: center;
      display: block;
      padding: 58px 0 18px 0;
      margin: 0 50px;
      border-bottom: 1px solid #F3F3F3;

      h4 {
        display: inline-block;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: #2A283D;
        margin-bottom: 0;
      }

      .title_lcl_change {
        &::before {
          content: "";
          width: 4px;
          height: 14px;
          display: inline-block;
          margin-right: 15px;
          background: #D75746;
        }
      }
    }

    .content {
      padding: 26px 133px 0 64px;

      .small-title {
        font-style: normal;
        font-weight: 550;
        font-size: 16px;
        color: #333;
        line-height: 20px;
        margin-bottom: 28px;
        margin-left: 32px;
        position: relative;

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          display: inline-block;
          background: #D75746;
          position: absolute;
          left: -16px;
          top: 5px;
        }
      }

      .wendangdr {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
        float: right;
        margin-bottom: 29px;

        i {
          margin-right: 10px;
        }
      }

      .must-hit {
        color: red;
        margin-right: 5px;
      }

      .cont-ms {
        margin-left: 64px;
        float: left;
        width: calc(100% - 64px);

        .smalltitle {
          margin-top: 30px;
          margin-bottom: 29px;
        }

        .renwushu_textArea {
          min-height: 139px;
          height: auto;
          //margin-bottom: 52px;
        }

        .ant-input:hover {
          border-color: #d9d9d9;
        }

        .textarea_len {
          position: relative;
          right: 11px;
          line-height: 0;
          bottom: -15px;
        }

        .ant-form-item-label {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .border-bottom {
      margin: 0 50px;
      width: calc(100% - 100px);
      height: 1px;
      //background: #F3F3F3;
      float: left;
    }

    .btn-group {
      margin-top: 64px;
      margin-bottom: 80px;
      margin-left: calc(50% - 101px);
      float: left;

      .ant-btn {
        height: 40px;
        width: 91px;
      }

      .ant-btn:last-child:hover, .ant-btn:last-child:focus {
        border-color: #d9d9d9;
        color: #333333;
      }

      .ant-btn:first-child {
        border: 1px solid #D75746;
        color: #D75746;
        margin-right: 20px;
      }

      .up-btn {
        //border-color: #D75746;
        //color: #D75746;
      }

      .next-btn {
        border-color: #D75746;
        background: #D75746;
        color: #ffffff;
        width: 155px;
      }
    }
  }
}
