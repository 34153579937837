html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;

}
i{
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
i,
em,
b,li{
  font-style: normal;
  list-style: none;
}
#root {
  width: 100%;
  background: #f6f7fb;
  font-family: Noto Sans SC;
}

/* 分页样式start */
#root .ant-pagination-item-link,
#root .ant-pagination-item {
  border: none;
}

#root .ant-select-selection {
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  height: 29px;
  margin-top: -2px;
}

#root .ant-select-selection:hover {
  color: #3E78ED;
}

#root .ant-select-selection__rendered {
  line-height: 27px;
  margin-right: 35px;
}

#root .ant-pagination-options {
  /*float: left;*/
}

/* 分页样式end */


p{
  margin-bottom:0;
}
.resource_container {
  height: 100%;
  overflow-y: auto;
}
html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol,
li {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #333;

}

i {
  font-style: normal;
}

body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #bbb;
}

body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #eee;
}

#root {
  position: relative;
}

.pt_wrap {
  height: 100%;
}

#root{
  overflow: hidden;
}

.resource_main::-webkit-scrollbar,
.main-content::-webkit-scrollbar,
.resource_container::-webkit-scrollbar,
.enterprisecenter::-webkit-scrollbar,
.education-information-wrap::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 7px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.resource_main::-webkit-scrollbar-thumb,
.main-content::-webkit-scrollbar-thumb,
.resource_container::-webkit-scrollbar-thumb,
.enterprisecenter::-webkit-scrollbar-thumb,
.education-information-wrap::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #bbb;
}

.resource_main::-webkit-scrollbar-track,
.main-content::-webkit-scrollbar-track,
.resource_container::-webkit-scrollbar-track,
.enterprisecenter::-webkit-scrollbar-track,
.education-information-wrap::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #eee;
}

.ant-select-dropdown{
  left: 0%;
  top: 32px;
}
.ant-pagination-options{
  position: relative;
}
.resource_main {
  width: 100%;
  height: 100%;
  font-family: Noto Sans SC;
  font-size: 14px;
}
p,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

.resource_header {
  width: 100%;
  height: 66px;
  z-index: 888;
}

.resource_footer {
  width: 100%;
  height: auto !important;
}

.resource_container {
  width: auto !important;
/*min-height: calc(100vh - 309px);*/
/*  background: #f6f7fb;*/
}
#root {
 /*min-width: 1200px;*/
 /*max-width: 1600px;*/
 /*margin: 0 auto;*/
  width: 100%;
  height: 100%;
 /*overflow: auto;*/
 /*overflow-x: hidden;*/
}
body{
  /*overflow: hidden;*/
}
body {
  width: 100% !important;
  height:auto!important;
}