.effective_1_5_5_wrap {
    .tongjiul1{
      display: flex;
      margin-bottom: 32px;
      li{
          margin-right: 20px;
          display: flex;
          align-items: center;
          .span{
              // width: 100%;
              // background: red;
              width: auto !important;
              margin-right: 15px;
              height: auto !important;
              line-height: normal !important;
          }
          .span1{
              margin-left: 15px;
          }
          .btn{
              width: 80px;
              height: 31px;
              margin-top: 5px;
              background: #EEEEEE;
              border: 1px solid #D9D9D9;
              box-sizing: border-box;
              line-height: 31px;
              text-align: center;
              border-radius: 3px;
          }
          .btn1{
              background: none;
              border: none;
              .ant-input{
                  background: #EEEEEE;
              }
              .ant-select-selection{
                  width: 80px;
                  height: 31px;
                  background: #EEEEEE;
              }
              .input_bg{
                  background: #ffffff;
              }
          }
      }
  }
  
  .conteBtnDivtadd{
    margin-top: 0;
    margin-left: 0;
    margin-right: 25px;
  }
  .conteBtnDiv {
    margin-top: 0 !important; 
    margin-bottom: 20px;
    // min-width: 108px;
    height: 30px;
    line-height: 12px;
    text-align: left;
    background: #D75746;
    border-radius: 4px;
    display: inline-block;
    float: left;
    margin-right: 20px;
    cursor: pointer;
    padding: 8px 14px;
    position: relative;
    color: #ffffff;

    .ant-input {
      position: absolute;
      z-index: 200;
      opacity: 0;
      filter: alpha(opacity=0);
      left: 0;
      top: 0;
      cursor: pointer;
    }

    .AddPlatformInformation {
      position: relative;
      top: 0;
      float: left;
    }

    .ExcelImport {
      position: relative;
      top: 0px;
      float: left;
      //margin-right:7px;
    }

    .span_svg1 {
      float: left;
      //margin-right:7px;
    }

    .icon {
      position: relative;
      top: 2px;
      float: left;
    }

    .conteBtnDivSpan {
      color: #FFFFFF;
      margin-left: 8px;
      font-size: 12px;
      line-height: 14px;
      float: left;
    }

  }
  .conteTable{
    margin-bottom: 20px;
  }
  // .cz{
  //   color: #D75746;
  //   position: relative;
  //   padding: 0 10px;
  //   cursor: pointer;
  // }
}
.Modal214 .ant-form-item-label{
  overflow: auto !important;
  line-height: normal !important;
  white-space: pre-wrap;
}

.conteTable214{
  margin-top: 14px;
  margin-bottom: 12px;
  
  width:100%;
  .table1{
    width:100%;
    .ant-table-tbody > tr > td{
      word-break: break-word;
      word-wrap: break-word;
      white-space: -o-pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: pre;
      white-space: pre-wrap;
      white-space: pre-line;
      display:table-cell;
      vertical-align:middle;
    }
  }
  .table1_top{
    width:90%;
  }
  .render-span{
    margin-bottom:0;
  }
  .chakan{
    cursor: pointer;
    color: #D75746;
  }
  .cz{
    color: #D75746;
    position: relative;
    padding:0 10px;
    cursor: pointer;
    &::after{
      position: absolute;
      right: 0;
      top: 5px;
      border-right: 1px solid #E0E0E0;
      content: "";
      height: 10px;
    }
  }
  .cz:last-child{
    &::after{
      display: none;
    }
  }
  .ant-table-wrapper{
    //border: 1px solid #e8e8e8;
    .ant-table-thead{
      .ant-table-align-center{
        background: rgba(215, 87, 70, 0.05);
      }
      .ant-table-row-cell-break-word{
        //background: rgba(215, 87, 70, 0.05);

      }
    }

  }
}